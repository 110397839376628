.theme-banner
{
  background: #56785c;
  color: $white;
  h1
  {
    color: $white;
  }

  h2
  {
    color: $light-gray;
  }

  p
  {
    font-size: 1.25rem;
    margin-bottom: 3rem;
  }

  blockquote
  {
    text-align: center;
    border: 0;
    margin-top: -2rem;
    padding: .5rem 0;

    p
    {
      color: $white;
      margin: 0 auto;
      display: inline-block;
      background-color: get-color('warning');
      padding: 0.5rem 2rem;
      font-size: 1.1rem;
      font-weight: 500;
    }

    a
    {
      color: $white;
    }
  }

  .button-group
  {
    margin-bottom: 0;
  }

  .button
  {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 1.1rem;
    background-color: get-color('primary');
    color: $white;

    i
    {
      font-size: 1.2rem;
    }
  }

  figure
  {
    img
    {
      height: auto !important;
    }
  }
}

.theme-default
{
  .theme-content
  {
    background-color: $white;
  }
}

.theme-grey
{
  .theme-content
  {
    background-color: $light-gray;
  }
}