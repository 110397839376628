.BEF-content
{
  h1, h2, h3, h4, h5, h6
  {
    font-family: $body-font-family;
    text-align: center;
  }

  h1
  {
    color: $black;
    margin: 0;
    padding: 2rem 0 0.5rem;
    font-size: 3rem;
  }

  h2
  {
    color: $dark-gray;
    margin: 1rem 0 0.5rem;
    font-size: 1.8rem;
    padding-bottom: 2rem;
  }

  h3
  {
    color: $dark-gray;
    margin: 1.5rem 0 0.5rem;
    font-size: 1.4rem;
    padding-bottom: 0;
  }

  h4
  {
    color: $dark-gray;
    margin: 1rem 0 0.25rem;
    font-size: 1.2rem;
    padding-bottom: 0;
  }

  table
  {
    border: 1px solid $medium-gray;
    box-shadow: 0 4px 8px rgba(200, 200, 200, 0.4);
  }

  a
  {
    color: get-color('secondary');
  }
  .button
  {
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #f4f4f4;
  }
  .button.hollow
  {
    color: get-color('secondary');
  }

  strong, b
  {
    font-weight: 200;
  }

  .parallax-container
  {
    min-height: 500px;
    background: transparent;
  }

  .ausverkauft
  {
    text-decoration: line-through;
  }

  .callout
  {
    h5 {
      text-align: left;
    }
  }
}

.BEF-content.footer
{
  h3, h4, h5, h6
  {
    text-align: left;
  }
}

