
#BEF-mainWrap
{
  text-align: center;
}

#BEF-mainFrame
{
}

header
{
  background-color: $header-background-color;
  color: $white;
  padding: 0.25rem 0;
  box-shadow: 0 4px 8px rgba(0,0,0,.3);

  .logo
  {
    display: inline-block;
    margin-right: 0.4rem;

    img
    {
      max-height: 40px;
    }
  }

  .menu
  {
    .menu-text
    {
      padding: 0 1rem;
    }
  }

  .title-bar
  {
    background-color: get-color('primary');
    z-index: 9;

    a {
      color: $white;
      display: inline-block;
      padding: 0 .25rem;
    }
  }
}

.wrapper
{
  max-width: 1100px;
  margin: auto;
}

.content
{
  min-height: 200px;
}

body.static-tpl .content
{
  min-height: auto;
}

.menu
{
  a
  {
    color: $white;
  }

  .is-active
  {
    background-color: $active-background-color;

  }

  .menu-text a
  {
    display: inline-block;
    padding: .7rem .25rem;
  }
  .menu-text a.is-active
  {
    background-color: transparent;
  }
}

.content
{
  padding-bottom: 2rem;
}