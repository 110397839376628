div.BEF-accept-cookies
{
  min-height: 50px;
  background-color: $header-background-color;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  font-size: 14px;
  color: #fff;
  box-shadow: 4px 4px 8px 4px rgba(0,0,0,.3);
  padding: 0.5rem 0;

  p
  {
    text-align: left;
    padding: .85em 0;
    margin: 0;
    font-size: .9rem;
    line-height: 1;

    a
    {
      color: $white;
      text-decoration: underline dotted $white;
    }
  }

  button
  {
    margin: 0;
    text-transform: uppercase;
  }
}
