@import 'foundation';

// *** Foundation XY-Grid
// @link https://foundation.zurb.com/sites/docs/xy-grid.html
$xy-grid: true;
$global-flexbox: false;

$foundation-palette: (
        primary: #494340,
        secondary: #56785c,
        success: #3adb76,
        warning: #c98919,
        alert: #cc4b37,
);
$primary-color: get-color('primary');

/// Color used for white ui items.
/// @type Color
$black: #222;
$white: #f4f4f4;
$light-gray: #e0e0e0;
$dark-gray: #333333;

/// Background color of the body.
/// @type Color
$body-background: $white;
$body-font-family: Montserrat,sans-serif;
$body-font-color: $dark-gray;

// *** Foundation Tooltips
// @link https://foundation.zurb.com/sites/docs/tooltip.html
$has-tip-font-weight: 'normal';
$has-tip-border-bottom: 0;
$has-tip-cursor: 'default';

// *** Foundation Top-Bar
// @link https://foundation.zurb.com/sites/docs/top-bar.html
$topbar-padding: 0.5rem;
$topbar-title-spacing: 0.4375rem 0;
$topbar-background: transparent;
$topbar-submenu-background: $white;

// *** Foundation Tabs
// @link https://foundation.zurb.com/sites/docs/tabs.html
$tab-background-active: get-color('primary');
$tab-active-color: $white;
$tab-item-font-size: rem-calc(14);
$tab-item-background-hover: $light-gray;

$button-background: get-color('secondary');
$button-background-hover: scale-color($button-background, $lightness: -15%);

$buttongroup-spacing: 1rem;

// *** Foundation Callout
// @link https://foundation.zurb.com/sites/docs/callout.html

$header-background-color : get-color('primary');
$active-background-color : get-color('secondary');

$menu-item-background-active: get-color('secondary');
