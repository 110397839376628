.BEF-form
{
  label
  {
    padding: 0 4px;
  }
  #captcha
  {
    height: 100%;
  }

  .captcha
  {
    .input-group-label
    {
      padding: 0;
    }
    .input-group-field
    {
    }
  }

  .required
  {
    float: right;
    font-size: 80%;
    font-style: italic;
    margin-top: 3px;
  }

  .invalid
  {
    label
    {
      color: get-color('alert');
    }
  }

  .BEF-btn-send
  {
    height: 60px;
  }

  .form-error
  {
    padding: 0 4px;
  }
}