.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  body
  {
    font-size: 1rem;
  }
  .BEF-content
  {
    h1
    {
      font-size: 1.65rem;
    }

    h2
    {
      font-size: 1.3rem;
    }

    h3
    {
      font-size: 1.2rem;
    }

    h3
    {
      font-size: 1.1rem;
    }
  }

  .title-bar-left
  {
    flex-grow: 2;
  }

  .top-bar .menu-text
  {
    display: none;
  }

  .theme-banner
  {
    blockquote
    {
      p
      {
        display: block;
      }
    }
  }
}

/* Medium and up */
@media screen and (min-width: 40em) {

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {

}